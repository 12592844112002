<template>
  <v-container fluid id="Usuarios" class="container-fundo pa-0" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

        <!-- Título da página -->
        <v-toolbar-title class="text-white title-page mt-3 ml-4 w-100"
          >Usuários
          {{gestor}}
        </v-toolbar-title>
        <!-- Fim Título da página -->

        <!-- Botões de ação cabeçalho -->
        <v-toolbar-title
          class="mt-0 mb-10 ml-4 pa-0 d-flex flex-column w-100"
          dark
          elevation="0"
        >
          <div class="d-flex align-center w-100" tabindex="-1">
            <v-text-field
              id="txtBuscar"
              ref="txtBuscar"
              v-on:keyup="filtro = $event.target.value"
              prepend-inner-icon="mdi-magnify"
              dense
              autofocus
              v-model="search"
              cache-items
              class="search-input"
              v-bind:class="{'search-input-longo': search_input_focus}"
              flat
              dark
              label="Buscar..."
              solo-inverted
              clearable
              @focus="onFocus($event)"
              @blur="onBlur($event)"
              tabindex="-1"
            >
            </v-text-field>

            <router-link
              :to="{ name: 'UsuarioCadastro' }"
              class="nav-link"
              aria-current="page"
              tabindex="-1"
            >
              <v-btn class="text-white" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
                <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
                <span>Novo</span>
              </v-btn>
            </router-link>

            <v-btn class="text-white mr-2"
                   :color="COR_PRINCIPAL"
                   elevation="0"
                   :small="isMobile"
                   v-on:click="bbb">
              <v-icon class="mr-1" color="light-blue accent-2"
                >mdi-view-headline</v-icon
              >
              <span>Opções</span>
            </v-btn>
          </div>
        </v-toolbar-title>
        <!-- Fim Botões de ação cabeçalho -->
      </v-toolbar-title>
    
    <!-- Help -->
    <v-expansion-panels
      class="pa-4 pt-0 px-0 expansion-panel d-none"
      accordion
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="panel-header font-weight-bold"
          disable-icon-rotate
        >
          Documentos Básicos
          <template v-slot:actions>
            <v-icon color="" class="icon-help">
              mdi-help-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          Conteúdo teste
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Fim Help -->

    <v-row class=" mt-n11 w-100 mx-0 mb-n1">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 w-100">
        <v-container class="container-principal container-rounded px-0 w-100 mx-0">

            <!-- Conteiner  -->
            <v-data-table
              id="dataTable"
              ref="dataTable"
              :items="store_Usuarios.dados"
              :headers="headers"
              :loading="loading"
              fixed-header
              dense
              :height="tableHeight"
              :items-per-page="20"
              hide-default-footer
              :hide-default-header="isMobile"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
              class="data-table"
            >
              <template #item="{ item }">
                <tr v-if="!isMobile">
                  <td class="td-edit" style="width: 3%">
                    <router-link :to="'/usuariocadastro/' + item.cod_usuario"
                      class="nav-link"
                      aria-current="page"
                      tabindex="-1"
                    >
                      <v-btn icon dark class="btn-icon mr-5" color="grey">
                        <v-icon class="icon-action"> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </router-link>
                  </td>
                  <td style="width: 10%" class="td-avatar py-1 pl-10">
                    <v-avatar size="50">
                      <v-img :src="item.foto"> </v-img>
                    </v-avatar>
                  </td>

                  <td style="width: 35%">
                    <span style="font-size: 15px !important;" class="font-weight-bold">{{ item.nome }}<br></span>
                    <span>{{ item.email }}</span>
                  </td>

                  <td style="width: 20%">
                    {{ item.celular }}
                  </td>

                  <td>
                    <span
                      v-if="item.situacao == 'Ativo'"
                      class="status_ativo mr-16"
                      >{{ item.situacao }}</span
                    >
                    <span
                      v-if="item.situacao == 'Pendente'"
                      class="status_pendente mr-16"
                      >{{ item.situacao }}</span
                    >
                  </td>

                
                </tr> 

                <!-- MOBILE ------------------------------------------------------------------------------- -->
                <tr v-if="isMobile">
                  <td>
                    <div style="width:25px !important" class="ml-n4">
                    <router-link
                      :to="{
                        name: 'UsuarioCadastro',
                        params: { cod_usuario: item.cod_usuario },
                      }"
                      class="nav-link"
                      aria-current="page"
                    >
                      <v-btn icon dark class="btn-icon" color="grey">
                        <v-icon class="icon-action"> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </router-link>
                    </div>
                  </td>

                  <td style="border:0">
                    <div style="width:25px !important" class="ml-n2">
                      <v-avatar size="45" class="ml-n1">
                        <v-img :src="item.foto"> </v-img>
                      </v-avatar>
                    </div>
                  </td>

                  <td style="width: 100%" >
                    <div style="font-size: 15px; display:block"              class="font-weight-bold">{{ item.nome }}</div>
                    <div style="font-size: 12px; color:#808080 !important; " class="mt-0" >{{ item.email }}</div>
                    <div style="font-size: 12px; color:#808080 !important; " class="mt-0 mb-2" >{{ item.celular }}</div>
                    <div style="font-size: 12px; color:#808080 !important; " class="mb-3 mt-1" >
                      <span
                        v-if="item.situacao == 'Ativo'"
                        class="status_ativo"
                        >{{ item.situacao }}</span
                      >
                      <span
                        v-if="item.situacao == 'Pendente'"
                        class="status_pendente mr-16"
                        >{{ item.situacao }}</span
                      >
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>

        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>

    <!-- Paginacao ------------------------------ -->   
    <div class="paginacao d-flex justify-end align-center" style="background-color: #FFFFFF;">
      <span style="color:grey; font-size:14px" class="mr-3">{{store_Usuarios.paginas}} linha(s)</span>
      <v-pagination
        v-model="currentPage"
        :length="store_Usuarios.paginas"
        total-visible="5"
        @input="handlePageChange"
        aria-controls="dataTable"
      />
    </div>

    <!-- <Footer /> -->
  </v-container>
</template>

<script>
import store_usuario from "../../store/store_usuario";
import store_Usuarios from "./store_Usuarios";
import { filterArrayByString } from "../../services/funcoes"
import { COR_PRINCIPAL } from "../../services/constantes";

export default {
  name: "Usuarios",
  components: {
  },

  data() {
    return {
      store_usuario: store_usuario,
      store_Usuarios: store_Usuarios,

      loading: false,
      search: null,
      search_input_focus : false,

      filtro: null,
      currentPage: 1,

      COR_PRINCIPAL : COR_PRINCIPAL,
      accentColor: '#f00',
      
      /* Título container-rounded */
      headers: [  { text: "", sortable: false },
                  { text: "Nome", value: "nome", sortable: true },
                  { text: "Telefone", value: "telefone" },
                  { text: "" },
                  { text: ""},
               ],
      selected: [],
      search2: '',

      gestor : null

    };
  },

  async created() {
    this.busca_Usuarios();
  },

  watch: {
    search(val) {
      this.filtro = val;
      this.currentPage = 1;
      this.busca_Usuarios_timeout(val);
    },
  },
 
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 90;
      else
        return window.innerHeight - 149 - 90;
    }

  },

  // mounted() {
    // onResize();
    // window.addEventListener("resize", this.onResize);
  // },

  // destroyed() {
    // window.removeEventListener("resize", this.onResize);
  // },
  
  methods: {
    test(){
      //console.log('111111')
    },

    onBlur() {
        this.search_input_focus = false;
    },

    onFocus() {
        this.search_input_focus = true;
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.busca_Usuarios();
    },

    async busca_Usuarios() {
      this.loading = true;
      var lo_params = { page: this.currentPage };
      if (this.filtro) {
        lo_params.filtro = this.filtro;
      }
      await this.store_Usuarios.UsuariosGet(lo_params);
      this.loading = false;

      // Posiciona o Scrool no começo da tabela 
      this.$vuetify.goTo('.data-table tbody tr',
                          { offset: 60,
                            container: '.v-data-table__wrapper'
                          }
                        )
    },

    busca_Usuarios_timeout(val) {
      if (val) {
        val = val.trim();
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.busca_Usuarios(val);
      }, 450);
    },

    async bbb() {
      const ls_RespUsuario = await this.store_Usuarios.UsuariosGet({cod_funcao: 4});
      //console.log(ls_RespUsuario);
    }

    // EXEMPLO    
        // onResize() {
        //   if (this.isMobile)
        //     this.tableHeight = window.innerHeight - this.$refs.toolbar.clientHeight + 30 - 90;
        //   else
        //     this.tableHeight = window.innerHeight - this.$refs.toolbar.clientHeight - 90;

        //   if (window.innerWidth < 769)
        //     this.isMobile = true;
        //   else
        //     this.isMobile = false;
        // },
        // toggleAll() {
        //   if (this.selected.length) this.selected = []
        //   else this.selected = this.desserts.slice()
        // },
        // changeSort(column) {
        //   console.log(column);
        //   if (this.pagination.sortBy === column) {
        //     this.pagination.descending = !this.pagination.descending
        //   } else {
        //     this.pagination.sortBy = column
        //     this.pagination.descending = false
        //   }
        // }

  },
};
</script>

<style scoped>

/*-----------------------------------*/
/*------ TABLE ------*/
/*-----------------------------------*/
.main-title {
  background: var(--accent-color); /* var("--cor-principal"); */
}

.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.accordion-panel {
  box-shadow: none !important;
}

.expansion-panel {
  border-radius: 8px 8px 0px 0px;
  border-radius: 25px 25px 0px 0px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

.panel-header {
  height: 60px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 17px;
  background: #f6f6f6 !important;
  color: #4476ba;
}

.v-btn {
  display: inline-block;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/

.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.wrapper-title,
.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

.search-input {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
}
.search-input-longo {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
  /* min-width: calc(100% - 15px); */
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }
  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    min-width: calc(100% - 15px);

  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.wrapper-title div {
  padding-left: 0px !important;
}

.container-fundo {
  height: calc(100vh -30px) !important;
}

.container-rounded {
  background: #fff !important;
  border-radius: 12px !important;
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
  padding-left: 27px;
  padding-right: 27px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.row-table-user-tablet {
  display: none;
}

#virtual-scroll-table-tablet {
  display: none;
}


@media (max-width: 1024px) {
  .td-avatar {
    padding-left: 16px !important;
  }

  .td-edit,
  .nav-link {
    padding-right: 0px !important;
  }

  .status_ativo,
  .status_pendente {
    margin-right: 4px !important;
  }
}

/* CELULAR */
@media (max-width: 599px) {

  .container,
  .container-principal {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #FFF !important;
    border-radius: 12px !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .title-page {
    margin-left: 16px !important;
  }

  .wrapper-title div {
    padding-left: 0px !important;
    margin-left: -8px;
  }

  .nav-link {
    padding-left: 0px;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }

  .avatar {
    margin: 0px 0px 0px 0px !important;
  }
  
  .td-edit-mobile {
    padding-right: 12px !important;
    padding-left: 0px !important;
  }

  .status-mobile {
    display: block;
  }

  .td-status {
    display: none;
  }
 
  .btn-icon {
    margin-right: 0px !important;
  }
  .search-input {
    margin: 0.8rem 0 1.2rem 0;
    min-width: auto;
  }
  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }
  .container-principal {
    max-width: 100% !important;
  }
}


</style>